import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import parser from 'html-react-parser';

import { CONFIG_SHAPE } from '../../state/config/config';
import { getQuestionAsAnObject, getHowToAsAnObject } from '../../utils/structuredData';

import { ReactComponent as InfoIcon } from '../../images/icons/information.svg';
import { ReactComponent as FaqIcon } from '../../images/icons/questionmark.svg';
import { ReactComponent as TipsIcon } from '../../images/icons/tips.svg';

import styles from './CategoryDescription.module.scss';

const Tips = ({ category, config, categoryPath }) => {
  if (!category.tips.block) return null;
  /** Generates the Structured Data for the Tips */
  let tipsJsonLd;
  const tipsJSX = parser(category.tips.block, {
    replace: (domNode) => {
      if (domNode.name === 'p' && !tipsJsonLd) {
        tipsJsonLd = getHowToAsAnObject({ domNode, url: `${config.baseUrl}/${categoryPath}` });
      }
    },
  });

  if (tipsJsonLd) {
    tipsJsonLd.name = `${category.tips.heading?.first} ${category.tips.heading?.second}`;
  }

  return (
    <section className={styles.tips}>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(tipsJsonLd) }} />
      <h2 className={styles.tipsHeading}>
        <div className={styles.tipsIcon}>
          <TipsIcon />
        </div>
        <div>
          <b>{category.tips.heading?.first}</b> {category.tips.heading?.second}
        </div>
      </h2>
      <div className={styles.tipsContent}>{tipsJSX}</div>
    </section>
  );
};

const Faq = ({ category }) => {
  if (!category.qa.block) return null;

  /** Generates the Structured Data for the FAQ */
  const faqJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [],
  };
  const faqJSX = parser(category.qa.block, {
    replace: (domNode) => {
      if (domNode.name === 'h3') {
        const question = getQuestionAsAnObject(domNode);
        if (question) faqJsonLd.mainEntity.push(question);
      }
    },
  });

  return (
    <section className={classnames(styles.faq, 'col-12-xs col-5-xl')}>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(faqJsonLd) }} />
      <h2 className={styles.faqHeading} id="qa">
        <div className={styles.faqIcon}>
          <FaqIcon />
        </div>
        <div>
          <b>{category.qa.heading?.first}</b> {category.qa.heading?.second}
        </div>
      </h2>
      <div className={styles.faqContent}>{faqJSX}</div>
    </section>
  );
};

const CategoryDescription = React.memo(({ categoryData, config }) => {
  if (!categoryData?.length) {
    return null;
  }

  const category = categoryData[categoryData.length - 1];

  if (!category.about?.block) return <div></div>;

  const categoryPath = categoryData.map((item) => item.slug).join('/');
  const aboutJSX = parser(category.about.block);

  return (
    <div id="description" className={classnames(styles.root, 'container')}>
      <div className={classnames(styles.row, 'row')}>
        <div className="col-12-xs col-7-xl">
          <section className={styles.about}>
            <h2 className={styles.aboutHeading} id="about">
              <InfoIcon className={styles.aboutIcon} viewBox="-10 -10 35 35" />
              <div>
                <b>{category.about.heading?.first}</b> {category.about.heading?.second}
              </div>
            </h2>
            <div className={styles.aboutContent}>{aboutJSX}</div>
          </section>
          {!!category.tips && <Tips category={category} config={config} categoryPath={categoryPath} />}
        </div>
        <Faq category={category} />
      </div>
    </div>
  );
});

CategoryDescription.displayName = 'CategoryDescription';
CategoryDescription.propTypes = {
  categoryPath: PropTypes.string,
  config: CONFIG_SHAPE,
  categoryData: PropTypes.object.isRequired,
};

const mapStateToProps = ({ config }) => ({
  config,
});
export default connect(mapStateToProps)(CategoryDescription);
