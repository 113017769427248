/* eslint-disable react/prop-types */
import React from 'react';
import styles from './pageDescription.module.scss';
import parse from 'html-react-parser';
import { useFrontload } from 'react-frontload';
import { getValidResponseBody } from '../../api';

const PageDescription = () => {
  /**
   * Load the component data
   */
  const { data: frontloadData, frontloadMeta } = useFrontload('client:page-description', async ({ contentApi }) => {
    const homePageData = await contentApi.getHomePageData();
    return { homePageData };
  });
  const homePageData = getValidResponseBody(frontloadData?.homePageData);

  if (frontloadMeta.pending) return null;
  if (!homePageData?.home_show_columns) return null;

  const descriptionColumn1 = parse(homePageData.home_column_1);
  const descriptionColumn2 = parse(homePageData.home_column_2);
  return (
    <section className={styles.pageDescription}>
      <div className="container">
        <div className="row">
          <div className="col-12-xs col-6-m">{descriptionColumn1}</div>
          <div className="col-12-xs col-6-m">{descriptionColumn2}</div>
        </div>
      </div>
    </section>
  );
};

export default PageDescription;
