export const getTextNodesAsString = (domNode) => {
  if (domNode.children) {
    return domNode.children.map((child) => (child.type !== 'text' ? getTextNodesAsString(child) : child.data)).join('');
  }
  if (domNode.type === 'text') return domNode.data;

  return '';
};

export const getStepTitleAndDescription = (domNode) => {
  if (domNode.children) {
    return domNode.children.reduce(
      (output, child) => {
        if (child.name === 'b') {
          output[0] += getTextNodesAsString(child);
        } else {
          output[1] += child.type !== 'text' ? getTextNodesAsString(child) : child.data;
        }

        return output;
      },
      ['', '']
    );
  }

  return ['', ''];
};

export const getQuestionAsAnObject = (domNode) => {
  const result = {
    '@type': 'Question',
    name: getTextNodesAsString(domNode),
    acceptedAnswer: {
      '@type': 'Answer',
      text: '',
    },
  };

  let elm = domNode.next;
  while (elm && elm.name !== 'h3') {
    result.acceptedAnswer.text += getTextNodesAsString(elm);
    elm = elm.next;
  }
  if (!result.acceptedAnswer.text.trim()) return null;
  return result;
};

export const getHowToAsAnObject = ({ domNode, url = '' }) => {
  const result = {
    '@context': 'http://schema.org',
    '@type': 'HowTo',
    name: '',
    description: getTextNodesAsString(domNode),
    step: [],
  };

  let elm = domNode.next;
  while (elm) {
    if (elm.name === 'ul') {
      elm.children
        .filter((item) => item.name === 'li')
        .forEach((item, index) => {
          const [title, description] = getStepTitleAndDescription(item);
          result.step.push({
            '@type': 'HowToStep',
            url,
            name: title,
            position: index + 1,
            itemListElement: [
              {
                '@type': 'HowToDirection',
                text: description,
                position: 1,
              },
            ],
          });
        });
    }
    elm = elm.next;
  }

  return result;
};
